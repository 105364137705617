/**
 * Color Swatch Selector Component
 *
 * PRODUCT V3 & V2
 *
 * Manages fabric-organized color swatches using the established single-pass deduplication pattern.
 * For v3 products, works with swatch-slider-lite.js for scroll container functionality.
 * For v2 products, provides basic scroll position preservation.
 *
 * Key Patterns:
 * - Single-pass fabric/swatch deduplication (matches liquid templates)
 * - Variant state management with event dispatching
 * - Smooth transitions between color selections
 *
 * Usage:
 * <swatch-selector handle="{{ product.handle }}" section-id="{{ section.id }}">
 *   {% render 'swatch' with swatch: swatch_product_swatch %}
 * </swatch-selector>
 *
 * @fires variant:changed - On color selection update
 * @see product-fabric-color-picker.liquid - For deduplication pattern
 */

import { debounce } from '../helper/functions';

class ColorSwatch extends HTMLElement {
  constructor() {
    super();
    // Store basic properties
    this.handle = this.getAttribute('handle');
    this.sectionId = this.getAttribute('section-id');

    // Initialize state
    this.transitionInProgress = false;
    this.lastSelectedSize = null;

    // Bind and debounce event handlers
    this.debouncedColorUpdate = debounce(this.onColorUpdate.bind(this), 50);

    // Store bound event listeners for cleanup
    this.boundVariantChangedHandler = this.handleVariantChanged.bind(this);

    // Setup event listeners
    this.setupEventListeners();
  }

  // Handle variant change events
  handleVariantChanged(event) {
    // Only update unavailability indicators if we're not in the middle of a color update
    if (!this.transitionInProgress) {
      this.updateUnavailabilityIndicators();
    }
  }

  // Setup all event listeners
  setupEventListeners() {
    // Listen for color swatch selection
    this.addEventListener('change', this.debouncedColorUpdate);

    // Listen for variant changes from the variant-select component
    document.addEventListener('variant:changed', this.boundVariantChangedHandler);
  }

  // Clean up event listeners when component is removed from DOM
  disconnectedCallback() {
    // Remove local event listeners
    this.removeEventListener('change', this.debouncedColorUpdate);

    // Remove global event listeners
    document.removeEventListener('variant:changed', this.boundVariantChangedHandler);
  }

  // Get all swatch containers and their scroll positions
  getSwatchContainersState() {
    const scrollPositions = {};

    // For v2 products - check containers by ID
    const limitedContainer = document.querySelector('#limited-swatch-container');
    const classicContainer = document.querySelector('#classic-swatch-container');
    if (limitedContainer) scrollPositions.limited = limitedContainer.scrollLeft;
    if (classicContainer) scrollPositions.classic = classicContainer.scrollLeft;

    // For v3 products - get all swatch slider containers
    const swatchContainers = document.querySelectorAll('[data-swatch-container]');
    swatchContainers.forEach((container) => {
      const id = container.closest('swatch-slider-lite')?.id;
      if (id) {
        scrollPositions[id] = container.scrollLeft;
      }
    });

    return scrollPositions;
  }

  // Get all swatch count containers and their values
  getSwatchCountsState() {
    const swatchCounts = {};
    const countContainers = document.querySelectorAll('[data-count-container]');

    countContainers.forEach((container) => {
      const fabricKey = container.getAttribute('data-count-container');
      const count = container.getAttribute('data-count');
      if (fabricKey && count) {
        swatchCounts[fabricKey] = count;
      }
    });

    return swatchCounts;
  }

  // Get the currently selected size
  getSelectedSize() {
    const selectedSizeInput = document.querySelector('.variant-radios input[type="radio"].size-peer:checked');
    return selectedSizeInput ? selectedSizeInput.value : null;
  }

  // Apply fade transition to product main content
  applyFadeTransition() {
    const productMain = document.querySelector('.js-product-main');
    if (productMain) {
      productMain.style.opacity = '0.7';
      productMain.style.transition = 'opacity 0.2s ease';
    }
  }

  // Restore scroll positions for all swatch containers
  restoreScrollPositions(scrollPositions) {
    // For v2 products
    if (scrollPositions.limited) {
      const limitedContainer = document.querySelector('#limited-swatch-container');
      if (limitedContainer) limitedContainer.scrollLeft = scrollPositions.limited;
    }

    if (scrollPositions.classic) {
      const classicContainer = document.querySelector('#classic-swatch-container');
      if (classicContainer) classicContainer.scrollLeft = scrollPositions.classic;
    }

    // For v3 products
    Object.entries(scrollPositions).forEach(([id, position]) => {
      if (id !== 'limited' && id !== 'classic') {
        const container = document.querySelector(`#${id}`);
        if (container) {
          const swatchContainer = container.querySelector('[data-swatch-container]');
          if (swatchContainer) {
            swatchContainer.scrollLeft = position;
          }
        }
      }
    });
  }

  // Restore swatch counts for all count containers
  restoreSwatchCounts(swatchCounts) {
    Object.entries(swatchCounts).forEach(([fabricKey, count]) => {
      const container = document.querySelector(`[data-count-container="${fabricKey}"]`);
      if (container) {
        container.setAttribute('data-count', count);
        container.innerHTML = `${count}&nbsp;${count === 1 ? 'color' : 'colors'}`;
      }
    });
  }

  // Reapply size selection
  reapplySizeSelection(selectedSize) {
    if (!selectedSize) return;

    const sizeInputs = document.querySelectorAll('.variant-radios input[type="radio"].size-peer');
    const matchingInput = Array.from(sizeInputs).find((input) => input.value === selectedSize);

    if (matchingInput) {
      matchingInput.checked = true;
      // Save the last selected size for future reference
      this.lastSelectedSize = selectedSize;
      // Manually trigger a change event to update the variant availability indicators
      matchingInput.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  // Reinitialize third-party widgets
  reinitializeWidgets() {
    // Reinitialize Yotpo widgets if present
    if (typeof yotpoWidgetsContainer !== 'undefined') {
      yotpoWidgetsContainer.initWidgets();
    }

    // Reinitialize Smart Wishlist
    if (typeof ReloadSmartWishlist !== 'undefined' && typeof $ !== 'undefined' && $.isFunction(ReloadSmartWishlist)) {
      ReloadSmartWishlist();
    }
  }

  // Update fabric-specific selected colors
  updateSelectedColors(fetchColorVariants) {
    document.querySelectorAll('[class*="js-selected-"]').forEach((label) => {
      const fetchedLabel = fetchColorVariants.querySelector(`.${label.className}`);
      if (fetchedLabel) {
        label.innerHTML = fetchedLabel.innerHTML;
      }
    });
  }

  // Set checked state for current swatch
  setCurrentSwatchChecked() {
    const currentSwatch = document.querySelector(`swatch-selector[handle="${this.handle}"]`);
    if (currentSwatch) {
      currentSwatch.querySelector('input').checked = true;
    }
  }

  // Update unavailability indicators based on selected size
  updateUnavailabilityIndicators() {
    // Get the currently selected size
    const selectedSize = this.getSelectedSize();
    if (!selectedSize) return;

    // Get all swatch selectors
    const swatchSelectors = document.querySelectorAll('swatch-selector');
    if (!swatchSelectors.length) return;

    // Get variant data from variant-radios component
    const variantRadios = document.querySelector('variant-radios');
    if (!variantRadios || !variantRadios.productMap) return;

    // Update unavailability indicators for each swatch
    requestAnimationFrame(() => {
      swatchSelectors.forEach((swatch) => {
        const productHandle = swatch.getAttribute('handle');
        const product = variantRadios.productMap.get(productHandle);

        if (!product) return;

        const hasSizeVariant = product.availableSizes?.has(selectedSize);
        const swatchLabel = swatch.querySelector('.swatch-label');

        if (!swatchLabel) return;

        let strikeThrough = swatchLabel.querySelector('.color-swatch-unavailable');

        if (!hasSizeVariant) {
          // Size not available for this color - show strike-through
          if (!strikeThrough) {
            strikeThrough = document.createElement('div');
            strikeThrough.classList.add('color-swatch-unavailable', 'size-sold-out');
            swatchLabel.appendChild(strikeThrough);
          }
          swatch.classList.add('color-unavailable');
        } else {
          // Size is available - remove strike-through
          if (strikeThrough) {
            swatchLabel.removeChild(strikeThrough);
          }
          swatch.classList.remove('color-unavailable');
        }
      });
    });
  }

  // Trigger variant change event
  triggerVariantChangeEvent() {
    const variantRadios = document.querySelector('variant-radios');
    if (variantRadios) {
      variantRadios.dispatchEvent(
        new CustomEvent('variant:changed', {
          detail: {
            variant: variantRadios.currentVariant
          }
        })
      );
    }
  }

  onColorUpdate(event) {
    event.preventDefault();

    // Prevent multiple concurrent updates
    if (this.transitionInProgress) return;
    this.transitionInProgress = true;

    // Store state before updating
    const scrollPositions = this.getSwatchContainersState();
    const swatchCounts = this.getSwatchCountsState();
    const selectedSize = this.getSelectedSize() || this.lastSelectedSize;

    // Apply fade transition
    this.applyFadeTransition();

    // Show loading state
    const productMain = document.querySelector('.js-product-main');
    if (productMain) {
      productMain.classList.add('loading');
    }

    // Fetch new product data - always get fresh data
    fetch(`/products/${this.handle}?section_id=${this.sectionId}`)
      .then((response) => response.text())
      .then((responseText) => {
        // Process the update with fresh data
        this.processColorUpdate(responseText, scrollPositions, swatchCounts, selectedSize);
      })
      .catch((e) => {
        console.error('Error updating color swatch:', e);
        // Restore opacity if there was an error
        const productMain = document.querySelector('.js-product-main');
        if (productMain) {
          productMain.style.opacity = '1';
          productMain.classList.remove('loading');
        }
        this.transitionInProgress = false;
      });
  }

  processColorUpdate(responseText, scrollPositions, swatchCounts, selectedSize) {
    // Parse the HTML response
    const html = new DOMParser().parseFromString(responseText, 'text/html');
    const mainContent = html.querySelector('.js-product-main');
    const fetchColorVariants = html.querySelector('#ColorVariants');

    // Use requestAnimationFrame to batch DOM updates
    requestAnimationFrame(() => {
      try {
        // Update the URL first to give immediate feedback
        window.history.replaceState({}, '', `/products/${this.handle}`);

        // Update fabric-specific selected colors
        this.updateSelectedColors(fetchColorVariants);

        // Update variant radios content
        const colorVariants = document.querySelector('#ColorVariants');
        if (colorVariants && fetchColorVariants) {
          colorVariants.innerHTML = fetchColorVariants.innerHTML;
        }

        // Update main product content
        const productMain = document.querySelector('.js-product-main');
        if (productMain && mainContent) {
          productMain.innerHTML = mainContent.innerHTML;

          // Fade back in
          productMain.style.opacity = '1';
          productMain.classList.remove('loading');
        }

        // Set checked state for current swatch
        this.setCurrentSwatchChecked();

        // Perform post-update operations in the next frame
        setTimeout(() => {
          // Restore settings from before the update
          this.reinitializeWidgets();
          this.restoreScrollPositions(scrollPositions);
          this.restoreSwatchCounts(swatchCounts);
          this.reapplySizeSelection(selectedSize);

          // Update unavailability indicators
          this.updateUnavailabilityIndicators();

          // Trigger variant change event
          this.triggerVariantChangeEvent();

          // End transition
          this.transitionInProgress = false;
        }, 100);
      } catch (error) {
        console.error('Error processing color update:', error);
        const productMain = document.querySelector('.js-product-main');
        if (productMain) {
          productMain.style.opacity = '1';
          productMain.classList.remove('loading');
        }
        this.transitionInProgress = false;
      }
    });
  }
}

customElements.define('swatch-selector', ColorSwatch);
